import { Component, ViewChild, Injector, Renderer } from '@angular/core';
import { FormComponent } from 'lib/components/form.component';
import { Rechnung } from 'app/hensl/classes/rechnung';
import { Rechnungsempfaenger } from 'app/hensl/classes/rechnungsempfaenger';
import { Artikel } from 'app/hensl/classes/artikel';
import { forkJoin } from 'rxjs';
import { CheckTagListComponent } from 'app/hensl/components/checktaglist.component';
import { AutoCompleteComponent } from 'lib/components/autocomplete.component';
import { Utils } from 'lib/utils/utils';
import { getCardAnimationDecoration } from 'app/hensl/animations';
import { isNumber } from 'util';
import { SettingsService } from 'app/hensl/services/settings.service';
import { ExtendedObservable } from 'lib/classes/extended-observable';
import { merge, call } from 'lib/utils/functions';
import { DialogBuilder } from 'lib/classes/dialog-builder';
import { FocusDirective } from 'lib/directives/focus.directive';
import { Transportart } from 'app/hensl/classes/transportart';

@Component(merge(getCardAnimationDecoration(), {
	selector: 'hensl-rechnungen',
	styles: [`
		button {
			margin: 1.5em 0 0;
		}
	`],
	template: `
		<h2>Rechnungen</h2>
		<div *ngIf="!id">
			<datatable
				[data-source]="getDataSource()"
				[columns]="[
					{ name: 'rechnungsnummer', label: 'Rechnungsnummer' },
					{ name: 'rechnungsdatum', label: 'Datum', type: 'date' }
				]"
			></datatable>
			<button type="button" mat-raised-button color="accent" routerLink="-1">+ Neue Rechnung</button>
			<br>
			<br>
			
			<a href="https://hensl-internal.chvaco.at/abrechnung" target="_blank">Abrechnung</a>
			|
			<a href="https://hensl-internal.chvaco.at/offene-posten" target="_blank">Offene Posten</a>
			
		</div>
		<form [formGroup]="form" *ngIf="id" style="width: 700px; max-width: 100%; float: left; margin-bottom: 2em; margin-right: 2em; ">
			<mat-card>
				<mat-card-subtitle>Kopfbereich</mat-card-subtitle>
				<mat-card-content>
					<div>
						<textfield
							label="Rechnungsnummer"
							value="current.rechnungsnummer"
							[enabled]="false"
						></textfield>
						<datefield
							label="Anlagedatum"
							value="current.anlagedatum"
							[enabled]="false"
						></datefield>
						<datefield
							#rechnungsdatum
							label="Rechnungsdatum"
							value="current.rechnungsdatum"
						></datefield>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Rechnungsempfänger</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							#autocompleteRechnungsempfaenger
							label="Rechnungsempfänger"
							value="current.rechnungsempfaengerid"
							[from]="rechnungsempfaenger"
							[wave]="true"
							(_change)="updateRechnungsadresse()"
							(_onKeyDownEnter)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
							(_onKeyDownArrowRight)="autocompleteRechnungsempfaenger.tabTo($event, 2)"
						></autocomplete>
					</div>
					<div>
						<mat-form-field style="width: 100%; ">
							<textarea
								matInput
								placeholder='Rechnungsadresse'
								[value]="current.rechnungsadresse"
								(change)="current.rechnungsadresse = $event.target.value"
								style="height: 5em; "
							></textarea>
						</mat-form-field>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Hauptbereich</mat-card-subtitle>
				<mat-card-content>
					<div>
						<autocomplete
							label="Artikel"
							value="current.artikelid"
							[from]="artikel"
							[wave]="true"
						></autocomplete>
						<textfield
							label="Artikeltext"
							value="current.artikeltext"
						></textfield>
					</div>
					<div>
						<numberfield
							label="Menge"
							value="current.menge"
							[wave]="true"
						></numberfield>
						<eurofield
							label="Preis / Einheit"
							value="current.preis"
						></eurofield>
					</div>
				</mat-card-content>
			</mat-card>
			<mat-card>
				<mat-card-subtitle>Sonstiges</mat-card-subtitle>
				<mat-card-content>
					<div>
						<eurofield
							label="Zusätzlicher Betrag"
							value="current.zusaetzlicherbetrag"
						></eurofield>
					</div>
				</mat-card-content>
			</mat-card>
			<hensl-checktag-list
				#checkTagList
				subtable="henslrechnungsattribute"
				idfieldname="rechnungid"
				filter="fuerrechnungen"
			></hensl-checktag-list>
			<form-buttons [delete]="false"></form-buttons>
		</form>
		<mat-card *ngIf="id" style="width: 550px; max-width: 100%; float: left; ">
			<mat-card-title>Letzten {{ getMaxRechnungen() }} Rechnungen</mat-card-title>
			<mat-card-content>
				<div *ngFor="let rechnung of heutigeRechnungen" style="margin-bottom: 0.4em; ">
					<div style="float: left; width: 100px; ">
						<a routerLink="../{{ rechnung.id }}">
							{{ rechnung.rechnungsnummer }}
						</a>
					</div>
					<div style="clear: both; "></div>
				</div>
				<div>
					<a routerLink="../-1" style="margin-top: 1em; ">+ Neue Rechnung</a>
				</div>
			</mat-card-content>
		</mat-card>
	`
}))
export class RechnungenComponent extends FormComponent<Rechnung>
{
	@ViewChild("autocompleteRechnungsempfaenger")
	autocompleteRechnungsempfaenger: AutoCompleteComponent;
	
	@ViewChild("rechungsdatum")
	rechungsdatum: any;
	
	getTable(): string { return "henslrechnungen"; }
	
	rechnungsempfaenger: Rechnungsempfaenger[];
	artikel: Artikel[];
	
	heutigeRechnungen: Rechnung[];
	rechnungsnummer: string;
	
	dialogBuilder: DialogBuilder;
	
	artikeleinheit: string;
	
	@ViewChild("checkTagList")
	checkTagList: CheckTagListComponent;
	
	constructor(
		private settings: SettingsService,
		private renderer: Renderer,
		injector: Injector
	) {
		super(injector);
		settings.load(() => {
			this.updateHeutigeRechnungen();
		});
	}
	
	onSelectAll(): ExtendedObservable<Rechnung[]> {
		return Rechnung.selectAll(this.data, 200);
	}
	
	onSelectCurrent(): ExtendedObservable<Rechnung> {
		return Rechnung.select(this.data, this.id);
	}
	
	getNew(): Rechnung {
		return new Rechnung(this.data);
	}
	
	onInit(): void {
		this.goToNewAfterEdit = true;
	}
	
	onRouteInit(): void {
		this.rechnungsnummer = null;
	}
	
	onLoadAll(): void {
		
	}
	
	onLoadCurrent(): void {
		this.data.loader++;
		forkJoin(
			this.data.selectAll(() => new Rechnungsempfaenger(), "henslrechnungsempfaenger"),
			this.data.selectAll(() => new Artikel(), "henslartikelliste")
		).subscribe(results => {
			this.rechnungsempfaenger = results[0];
			this.artikel = results[1];
			if (this.isNew) {
				Rechnung.neueRechnungsnummer(this.data).convert(x => x.nummer).subscribe(nummer => {
					this.current.rechnungsnummer = (nummer - 30000) + " / " + (Utils.getYear() - 2000);
				});
			} else {
				
			}
			this.checkTagList.onLoadCurrent();
			//FocusDirective.focus(this.renderer, this.rechungsdatum);
			this.data.loader--;
		});
	}
	
	beforeSave(callback: () => void): void {
		if (this.isNew) {
			Rechnung.neueRechnungsnummer(this.data).subscribe(result => {
				this.current.saveNummernkreis(result).subscribe(() => {
					super.beforeSave(callback);
				});
			});
		} else {
			super.beforeSave(callback);
		}
	}
	
	afterSave(callback: () => void): void {
		this.checkTagList.onSave();
		this.updateHeutigeRechnungen();
		super.afterSave(callback);
	}
	
	updateHeutigeRechnungen() {
		return Rechnung.selectAll(this.data, this.getMaxRechnungen()).subscribe(result => {
			this.heutigeRechnungen = result;
		});
	}
	
	getInternalHostname() {
		return Utils.getInternalHostname();
	}
	
	getMaxRechnungen() {
		return this.settings.getValue('view.maxHeutigeAuftraege');
	}
	
	updateRechnungsadresse() {
		const id = this.current.rechnungsempfaengerid;
		if (!id) {
			return;
		}
		for (const x of this.rechnungsempfaenger) {
			if (x.id === id) {
				this.current.rechnungsadresse = x.adresse;
				return;
			}
		}
	}
}